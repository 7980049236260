<template>
  <div class="vx-desktop-main-container">
    <fx-menu
      :menu-items="menuItems"
      class="vx-desktop-main-menu"
      @logout="logout()"
      @profil="openProfil"
    />
    <div class="vx-desktop-main-tab-navigator">
      <v-tabs
        v-model="$store.state.selectedTabIndex"
        class="vx-desktop-main-tab-navigator-tabs"
        height="38px"
        show-arrows
      >
        <v-tabs-slider color="teal lighten-3" />
        <draggable
          :disabled="false"
          style="width:100%;display:flex;flex-direction:row;"
        >
          <transition-group style="width:100%;display:flex;flex-direction:row;z-index: 1">
            <v-tab
              v-for="(elem) in $store.getters.getTabs()"
              :key="elem.instance._uid"
              @click.middle="closeTab(elem)"
              @change="selectTab(elem)"
            >
              <v-icon>{{ elem.item.icon }}</v-icon>
              <div style="padding-left: 8px">
                {{ elem.item.module_libelle }}
              </div>
              <v-btn
                icon
                text
                @click="closeTab(elem)"
              >
                <v-icon class="btn-close">
                  close
                </v-icon>
              </v-btn>
            </v-tab>
          </transition-group>
        </draggable>
      </v-tabs>
      <template v-for="tab in $store.getters.getTabs()">
        <component
          :is="tab.item.route"
          v-show="isSelected(tab)"
          ref="mycomp"
          :key="tab.instance._uid"
          :parametres="tab.item"
          class="vx-desktop-main-tab-navigator-content"
          v-bind="tab.instance._props"
        />
      </template>
      <accueil
        v-show="hasSelection()"
        :datas="menuItems"
        class="vx-desktop-main-tab-navigator-content"
        @addTab="addTab"
      />
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Accueil from '@/wikaz-core/ui/modules/accueil/Accueil'
import FxMenu from '@/wikaz-core/ui/modules/home/menu/FxMenu'
export default {
    name: 'VxDesktopMain',
    components: {
        FxMenu,
        Accueil,
        draggable,
    },
    data () {
        return {
            menuItems: [],
            ready: false,
            params: [],
        }
    },
    created () {
        this.setWatcher()
    },
    mounted () {
        this.getMenu()
    },
    methods: {
        setWatcher () {
            this.$store.watch(function (state) {
                return state.auth.isLoggedIn
            },
            (n) => {
                if (n) {
                    this.getMenu()
                } else {
                    this.$store.commit('SET_SELECTED_TAB', null)
                }
            }, { deep: true },
            )
            this.$store.watch(function (state) {
                return state.selectedTab
            },
            (n) => {
                if (! n) {
                    this.getMenu()
                }
            },
            )
        },
        getMenu () {
            this.setParams()
            this.$wikaz.request.get('api/wikaz/structure/menu?type=1').then((data) => {
                this.menuItems = data.menu
                this.$store.commit('UPDATE_MENU', data.menu)
                this.getParams()
                this.$store.commit('UPDATE_USER', data.user)
                this.$store.commit('SET_THEME_SOMBRE', (data.user.user_theme_dark === 1))
                this.ready = true
            }).catch(() => {
                this.ready = true
            })
        },
        setParams () {
            let query = window.location.search.substring(1).split('&')
            if (query !== this.params) {
                query.map((q) => {
                    if (q) {
                        this.params.push(q)
                    }
                })
            }
            let cleanUri = location.protocol + '//' + location.host + location.pathname
            window.history.replaceState({}, document.title, cleanUri)
        },
        getParams () {
            if (this.menuItems.length > 0) {
                let param = []
                for (let i = 0, l = this.params.length; i < l; i ++) {
                    let temp = this.params[i].split('=')
                    param[temp[0]] = temp[1]
                    if (param[temp[0]] === param['module_id']) {
                        this.menuItems.some(e => {
                            let bool = parseInt(e.module_id) === parseInt(param['module_id'])
                            if (bool) {
                                this.$store.commit('ADD_TAB', e)
                            }
                            return bool
                        })
                    }
                }
                this.params = []
            }
        },
        logout () {
            this.$auth.logout()
        },
        openProfil () {
            this.$store.commit('ADD_TAB', {
                module_libelle: 'Mon Profil',
                action: 1,
                color: '#347ab8',
                route: 'vx-profil',
                title: 'Profil',
            })
        },
        addTab (tab) {
            this.$store.commit('ADD_TAB', tab)
        },
        hasSelection () {
            return this.$store.getters.getSelectedTab() === null
        },
        isSelected (tab) {
            return this.$store.getters.getSelectedTab() === tab.instance._uid
        },
        selectTab (ev) {
            this.$store.commit('SET_SELECTED_TAB', ev.instance._uid)
            this.$emit('selectTab', ev)
        },
        closeTab (ev) {
            this.$emit('closeTab', ev)
            this.$store.commit('REMOVE_TAB', ev.instance._uid)
        },
        isActive (index) {
            return this.$store.getters.getSelectedTab() === index
        },
    },
}
</script>
<style>
    .vx-desktop-main-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
    }

    .vx-desktop-main-menu {

    }

    .vx-desktop-main-tab-navigator {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 1fr;
        overflow: auto;
    }

    .vx-desktop-main-tab-navigator-tabs {
        width: 100%;
        overflow: auto;
    }

    .vx-desktop-main-tab-navigator-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .btn-close {
        font-size: 16px !important;
        color: #EF9A9A !important;
    }

    .btn-close:hover {
        font-size: 16px !important;
        color: #F44336 !important;
    }
</style>