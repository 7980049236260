<template>
  <v-container fluid>
    <v-row
      v-if="$store.getters.getMenu()"
      dense
      style="gap: 20px;padding: 10px"
    >
      <accueil-vignette
        v-for="(menuItem,index) in $store.getters.getMenu()"
        :key="index"
        :bkgd_color="menuItem.color || '#a200ff'"
        :disabled="!(menuItem.route)"
        :icon="menuItem.icon"
        :index="index"
        :is-configurable="false"
        :libelle="menuItem.module_libelle"
        :menu-item="menuItem"
        @select="f_open(menuItem)"
      />
    </v-row>
  </v-container>
</template>
<script>
import AccueilVignette from '@/wikaz-core/ui/modules/accueil/AccueilVignette'
export default {
    name: 'VxAccueilPwa',
    components: { AccueilVignette },
    data () {
        return {
            degrade: [
                'linear-gradient(180deg,#f44881,#ec454f)',
                'linear-gradient(0deg,#f19a1a,#ffc73c)',
                'linear-gradient(180deg,#21c8f6,#637bff)',
                'linear-gradient(180deg,#6edcc4,#1aab8b)',
                'linear-gradient(0deg,#8b60ed,#b372bd)',
            ],
        }
    },
    methods: {
        f_open (item) {
            this.$store.commit('SET_VIEW_PWA', {
                component: item.route,
                module_libelle: item.module_libelle,
            })
        },
    },
}
</script>