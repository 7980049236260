<template>
  <div class="accueil-container-principal">
    <div class="accueil-container-secondaire">
      <v-text-field
        v-model="searchField"
        dense
        outlined
        hide-details
        placeholder="Filtre"
        @input="search"
      />
      <v-container
        grid-list-xl
        fill-height
        fluid
        style="height: 100%;overflow: auto"
      >
        <v-layout
          row
          wrap
          justify-center
          align-content-center
          style="height: auto"
        >
          <accueil-vignette
            v-for="(menuItem,index) in filteredData"
            v-show="configurationEtat || $store.getters.userHasEpingle(menuItem.module_id)"
            :key="index"
            :bkgd_color="menuItem.color || '#a200ff'"
            :disabled="!(menuItem.route)"
            :icon="menuItem.icon"
            :index="index"
            :is-configurable="configurationEtat"
            :libelle="menuItem.module_libelle"
            :menu-item="menuItem"
            @select="addTab(menuItem)"
          />
        </v-layout>
      </v-container>
      <div class="accueil-footer">
        <fx-icon
          v-if="!configurationEtat"
          :size="32"
          color="grey"
          icon="settings"
          :badge="getNbModuleNonEpingle(filteredData)"
          @click="setEtatConfiguration(true)"
        />
        <fx-icon
          v-else
          :size="32"
          color="green"
          icon="done"
          @click="setEtatConfiguration(false)"
        />
        <div style="width: 100px; font-size: 13px">
          Version : <span style="font-weight: bold">{{ version }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccueilVignette from './AccueilVignette'

export default {
    name: 'Accueil',
    components: { AccueilVignette },
    props: {
        datas: {
            type: Array,
            default: null,
        },
    },
    data () {
        return {
            colors: ['#603cba', '#a200ff', '#f47835', '#d41243', '#8ec127', '#00aba9', '#2d89ef'],
            filteredData: this.datas,
            searchField: '',
            version: 0,
            configurationEtat: false,
        }
    },
    watch: {
        datas: function () {
            this.search()
        },
    },
    mounted () {
        this.version = this.$wikaz.getInstance().app.version
    },
    methods: {
        addTab (tab) {
            this.$emit('addTab', tab)
        },
        search () {
            if (this.searchField !== '') {
                let filteredArray = []
                this.datas.map((item) => {
                    if (item.module_libelle.toLowerCase().toString().includes(this.searchField.toLowerCase())) {
                        filteredArray.push(item)
                    }
                })
                this.filteredData = filteredArray
            } else {
                this.filteredData = this.datas
            }
        },
        setEtatConfiguration (etat) {
            this.configurationEtat = etat
        },
        getNbModuleNonEpingle (data) {
            let nbModuleNonEpingle = 0
            for (let i = 0; i < data.length; i ++) {
                if (! this.$store.getters.userHasEpingle(data[i].module_id)) {
                    nbModuleNonEpingle ++
                }
            }
            return nbModuleNonEpingle
        },
    },
}
</script>
<style lang="scss" scoped>

    .accueil-container-principal {
        padding: 30px;
        height: 100%;
        width: 100%;
        overflow:auto;
    }

    .accueil-container-secondaire {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-row-gap: 20px;
        overflow:auto;
    }

    .accueil-search {
        width:100%;
    }

    .accueil-vignettes-container {
        display: flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        overflow:auto;
        padding: 20px
    }

    .accueil-footer {
        height: 45px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

</style>