<template>
  <v-navigation-drawer
    ref="navDrawer"
    v-model="drawer"
    :mini-variant="mini"
    mobile-breakpoint="0"
  >
    <v-list
      dense
      height="auto"
    >
      <fx-item-renderer-menu
        :icon="mini ? 'keyboard_arrow_right' : 'keyboard_arrow_left'"
        :mini="mini"
        libelle="Menu"
        @click="mini = !mini"
      />
    </v-list>
    <v-divider />
    <v-list
      dense
      height="auto"
      style="margin: 0;padding: 0"
    >
      <fx-item-renderer-menu
        :mini="mini"
        color="#347ab8"
        icon="view_quilt"
        libelle="Accueil"
        @click="openHome"
      />
      <fx-item-renderer-menu
        :mini="mini"
        color="#347ab8"
        icon="portrait"
        libelle="Mon profil"
        @click="openProfil"
      />
      <!--<fx-item-renderer-menu
        :mini="mini"
        color="red"
        icon="support"
        libelle="Support"
        @click="openSupport"
      />-->
    </v-list>
    <v-divider />
    <v-list
      v-if="menuItems"
      dense
      height="auto"
    >
      <template v-for="(item, index) in menuItems">
        <fx-item-renderer-menu
          v-if="getEtatEpingle(item.module_id)"
          :key="index"
          :color="item.color"
          :icon="item.icon"
          :libelle="item.module_libelle"
          :badge="item.badge"
          :mini="mini"
          @click="addTab(item)"
        />
      </template>
    </v-list>
    <cx-waiting
      v-else
      :size="30"
      style="padding-top: 5px;padding-bottom: 5px;height: calc( 100% - 345px )"
    />
    <v-list
      dense
      height="auto"
      style="position: fixed;bottom: 0"
    >
      <v-divider style="margin: 0" />
      <fx-item-renderer-menu
        :mini="mini"
        color="red"
        icon="power_settings_new"
        libelle="Déconnexion"
        @click="logout"
      />
    </v-list>
    <v-dialog
      v-model="showSupport"
      width="500px"
      @click:outside="f_fermer_support"
    >
      <v-card style="width: 580px;height: auto;display: flex;flex-direction: column;padding: 10px">
        <v-card-title>Créer un ticket</v-card-title>
        <v-card-text>
          <div style="display: flex;">
            Afin de faciliter le traitement de la demande de support, merci de signaler un seul problème par ticket.
          </div>
          <div style="display: flex;">
            Lorsque le problème sera résolu , un email vous sera envoyé.
          </div>
          <fx-text-input
            v-model="dataSupport.objet"
            placeholder="Objet"
            label="Objet"
          />
          <fx-text-area
            v-model="dataSupport.description"
            placeholder="Contenu"
            label="Contenu"
          />
        </v-card-text>
        <div style="width: 100%;height: 100%" />
        <div style="display: flex;justify-content: flex-end;margin-top: 10px">
          <v-btn
            color="success"
            small
            @click="f_envoyer_support"
          >
            Envoyer
          </v-btn>
          <v-btn
            style="margin-left: 10px"
            color="error"
            small
            @click="f_fermer_support"
          >
            Fermer
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>

export default {
    name: 'FxMenu',
    props: {
        menuItems: {
            type: Array,
            default: () => [],
        },
    },
    data () {
        return {
            version: null,
            drawer: true,
            mini: true,
            showSupport: false,
            dataSupport: {
                objet: null,
                description: null,
            },
        }
    },
    mounted () {
        this.version = this.$wikaz.getInstance().app.version
    },
    methods: {
        openProfil () {
            this.$emit('profil')
        },
        addTab (tab) {
            if (tab.route.indexOf('http') === 0) {
                this.goto(tab.route)
            } else {
                this.$store.commit('ADD_TAB', tab)
            }
        },
        logout () {
            this.$emit('logout')
        },
        openHome () {
            this.$store.commit('SET_SELECTED_TAB', null)
        },
        openSupport () {
            this.showSupport = true
        },
        f_fermer_support () {
            this.showSupport = false
        },
        f_envoyer_support () {
            this.$wikaz.request.post('api/wikaz/structure/supports', this.dataSupport).then(() => {
                this.dataSupport = {
                    objet: null,
                    description: null,
                }
                this.showSupport = false
            })
        },
        goto (route) {
            window.open(route)
        },
        getEtatEpingle (moduleId) {
            return this.$store.getters.userHasEpingle(moduleId)
        },
    },
}
</script>
<style lang="scss" scoped>
    .fx-menu-container {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 10px auto auto 200px auto ;
        border: 1px solid green;
        // top: 0;
        // left: 0;
        // transform: translateX(0) !important;
    }
</style>