<template>
  <vx-mobile-main v-if="$store.getters.isMobile()" />
  <vx-desktop-main v-else />
</template>
<script>
import VxDesktopMain from './VxDesktopMain'
import VxMobileMain from './VxMobileMain'
export default {
    name: 'VxSwitch',
    components: {
        VxMobileMain,
        VxDesktopMain,
    },
}
</script>