<template>
  <div>
    <cx-toolbar :tab="$store.getters.getViewPwa()" />
    <v-container
      class="overflow-y-auto"
      style="height: calc(100% - 50px);padding: 0;max-width: unset"
    >
      <component
        :is="$store.getters.getViewPwa().component"
        v-if="$store.getters.getViewPwa()"
        :data-provider="$store.getters.getViewPwa().dataProvider"
        style="height: 100%"
      />
      <vx-accueil-pwa v-else />
    </v-container>
    <cx-menu-pwa />
  </div>
</template>
<script>

import CxToolbar from './menu/CxToolbar'
import CxMenuPwa from './menu/CxMenuPwa'
import VxAccueilPwa from '@/wikaz-core/ui/modules/accueil/VxAccueilPwa'
export default {
    name: 'VxMobileMain',
    components: {
        VxAccueilPwa,
        CxMenuPwa,
        CxToolbar,
    },
}
</script>