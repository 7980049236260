<template>
  <v-flex
    v-if="menuItem"
    xs12
    sm12
    md6
    lg3
    xl3
    style="cursor: pointer"
    @click="select()"
  >
    <v-hover>
      <template v-slot="{ hover }">
        <div
          style="padding: 10px;display:flex;flex-direction:column;align-items:center;border-radius: 15px;position: relative"
          :style="{background: 'linear-gradient(180deg,'+menuItem.color+','+(menuItem.color_end ? menuItem.color_end : menuItem.color)+')'}"
          :class="hover ? 'card-hover' : ''"
        >
          <v-img
            v-if="menuItem.url_image"
            contain
            :src="$wikaz.getInstance().outils.urls.getMenuImage(menuItem.url_image)"
            height="140px"
          />
          <v-icon
            v-else
            style="height: 140px"
            color="white"
            :size="90"
          >
            {{ menuItem.icon }}
          </v-icon>
          <div style="padding: 10px;color: white;font-size: 18px;font-weight: unset;font-family: Open Sans,Helvetica,Arial,sans-serif">
            {{ libelle }}
          </div>
          <v-card-actions v-if="isConfigurable">
            <v-spacer />
            <fx-checkbox
              v-model="epingle"
              :true-value="true"
              :false-value="false"
              label="Epingler"
              @on-change="setEpingle"
            />
            <v-spacer />
          </v-card-actions>
          <div
            v-if="menuItem.badge"
            :class="hover ? 'badge-element-selected' : 'badge-element'"
          >
            {{ menuItem.badge }}
          </div>
        </div>
      </template>
    </v-hover>
  </v-flex>
</template>
<script>
export default{
    name: 'AccueilVignette',
    props: {
        menuItem: {
            type: Object,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        libelle: {
            type: String,
            default: null,
        },
        bkgd_color: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isConfigurable: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            colors: ['#603cba', '#a200ff', '#f47835', '#d41243', '#8ec127', '#00aba9', '#2d89ef'],
            epingle: false,
        }
    },
    mounted () {
        this.epingle = this.getEtatEpingle(this.menuItem.module_id)
    },
    methods: {
        select () {
            if (! this.isConfigurable) {
                if (! this.disabled) {
                    this.$emit('select')
                }
            }
        },
        getColor () {
            return this.colors[Math.floor(Math.random() * this.colors.length)]
        },
        getEtatEpingle (moduleId) {
            return this.$store.getters.userHasEpingle(moduleId)
        },
        setEpingle () {
            if (this.epingle) {
                this.$store.commit('ADD_EPINGLE', this.menuItem.module_id)
            } else {
                this.$store.commit('REMOVE_EPINGLE', this.menuItem.module_id)
            }
        },
    },
}
</script>
<style scoped lang="scss">

    .card-hover {
        // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    .myVignette{
        margin: 12px 12px;
        color: #2c2d2d;
        opacity:.8;
        text-align: center;
        // border: 0.5px solid #555555;
        border-radius: 6px;
        overflow:hidden;
        //
        box-shadow: 1px 1px 1px 0 #ccc;
        transition: all ease .2s;
        &.disabled{
            opacity: 0.4;
            &:hover{
                cursor: not-allowed;
            }
        }
        &:not(.disabled):hover{
            //opacity:1;
            cursor: pointer;
            transform: translateX(1px) translateY(1px);
            box-shadow:2px 2px 4px 2px #ccc;
            >.frame{
                box-shadow: inset 10px 10px 60px 10px rgba(255,255,255, 0.1);
            }
        }
        .icon {
            display: flex;
            align-items: center;
            //border: 1px solid;
            justify-content: center;
            font-size:3rem;
            height: 115px;
            width: 95px;
        }
        h3{
            width:70%;
            margin: 10px 0 0;
            text-align: center;
            font-size:.8rem;
        }
    }
    .badge-element-selected {
        position: absolute;
        top: -16px;
        right: -12px;
        background-color: #4CAF50;
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: large;
        // box-shadow:0px 2px 4px 2px wheat;
    }

    .badge-element {
        position: absolute;
        top: -12px;
        right: -5px;
        background-color: #4CAF50;
        color: white;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: large;
    }
</style>