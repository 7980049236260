<template>
  <div style="height: 50px">
    <v-toolbar
      dense
      flat
      fixed
      style="color: white"
      color="#053E64"
    >
      <v-btn
        small
        text
        icon
        @click="clickLeft"
      >
        <v-icon
          v-if="tab"
          color="#FFFFFF"
        >
          {{ (tab.config && tab.config.iconLeft) ? tab.config.iconLeft : 'menu' }}
        </v-icon>
        <v-icon
          v-else
          color="#FFFFFF"
        >
          menu
        </v-icon>
      </v-btn>
      <v-toolbar-title>{{ tab && tab.module_libelle ? tab.module_libelle : 'Accueil' }}</v-toolbar-title>
      <v-spacer />
      <div v-if="tab && tab.config && tab.config.iconRight">
        <v-btn
          small
          text
          icon
          @click="clickRight"
        >
          <v-icon color="#FFFFFF">
            {{ tab.config.iconRight }}
          </v-icon>
        </v-btn>
      </div>
      <slot />
    </v-toolbar>
  </div>
</template>

<script>
export default {
    name: 'CxToolbar',
    props: {
        tab: {
            type: Object,
            default: null,
        },
    },
    methods: {
        clickLeft () {
            if (this.tab && this.tab.config && this.tab.config.clickLeft) {
                this.tab.config.clickLeft()
            } else {
                this.$root.$emit('refreshMenu')
                this.$store.commit('SET_ETAT_SIDEBAR', true)
            }
        },
        clickRight () {
            if (this.tab && this.tab.config && this.tab.config.clickRight) {
                this.tab.config.clickRight()
            } else {
                this.$emit('clickRight')
            }
        },
    },
}
</script>