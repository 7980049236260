<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    fixed
    temporary
  >
    <v-list-item>
      <v-list-item-avatar
        v-if="dataUser.avatar !== undefined"
        size="50"
      >
        <img
          :src="'data:image/jpeg;base64,'+ dataUser.avatar"
          alt=""
        >
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ dataUser.prenom }} {{ dataUser.nom }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list
      dense
      height="auto"
    >
      <fx-item-renderer-menu
        color="#347ab8"
        icon="view_quilt"
        libelle="Accueil"
        @click="goToAccueil()"
      />
      <fx-item-renderer-menu
        color="#347ab8"
        icon="portrait"
        libelle="Profil"
        @click="goToProfil()"
      />
      <!--<fx-item-renderer-menu color="orange" @click="goToSupport()" icon="help" libelle="Support"/>-->
    </v-list>
    <v-divider />
    <v-list
      v-if="dataMenu"
      height="auto"
      dense
    >
      <template v-for="(item, index) in dataMenu">
        <fx-item-renderer-menu
          :key="index"
          :color="item.color"
          :icon="item.icon"
          :libelle="item.module_libelle"
          :badge="item.badge"
          @click="actionForItem(item)"
        />
      </template>
    </v-list>
    <cx-waiting
      v-else
      :size="30"
      style="padding-top: 5px;padding-bottom: 5px;height: auto"
    />
    <v-list
      dense
      height="auto"
      style="position: fixed;bottom: 0px"
    >
      <v-divider style="margin: 0px" />
      <fx-item-renderer-menu
        color="red"
        icon="power_settings_new"
        libelle="Déconnexion"
        @click="logoutPost()"
      />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
    name: 'CxMenuPwa',
    data () {
        return {
            dialogPassword: false,
            dataMenu: null,
            dataUser: {},
            test: false,
        }
    },
    computed: {
        drawer: {
            get () {
                return this.$store.state.sidebar
            },
            set (val) {
                this.$store.commit('SET_ETAT_SIDEBAR', val)
            },
        },
        filteredList () {
            return this.items.filter(item => {
                return item.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
    },
    mounted () {
        this.getMenu()
        this.$root.$on('refreshMenu', () => {
            this.getMenu()
        })
    },
    methods: {
        logoutPost () {
            this.dataMenu = null
            this.$auth.logout()
        },
        getMenu () {
            this.$wikaz.request.get('api/wikaz/structure/menu?type=2').then((data) => {
                this.dataMenu = data.menu
                this.$store.commit('UPDATE_MENU', data.menu)
                this.dataUser = data.user
                this.$store.commit('UPDATE_USER', data.user)
                this.$store.commit('SET_THEME_SOMBRE', (data.user.user_theme_dark === 1))
            }).catch(() => {
            })
        },
        actionForItem (item) {
            item.component = item.route
            switch (item.action) {
            case '1':
                this.$store.commit('SET_VIEW_PWA', item)
                break
            case '2':
                this.$store.commit('SET_VIEW_PWA', item)
                break
            case '3':
                window.open(item.component)
                break
            default:
            }
        },
        goToAccueil () {
            this.$store.commit('SET_VIEW_PWA', null)
        },
        goToProfil () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-profil',
                module_libelle: 'Profil',
            })
        },
        goToSupport () {

        },
    },
}
</script>